import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Footer = () => {
    return (
        <Box component="footer" sx={{ py: 6 }}>
        <Container maxWidth="lg">
            <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Notifications-GS. Todos los derechos reservados.
            </Typography>
        </Container>
        </Box>
    );
};

export default Footer;