import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, Box, Select, MenuItem, InputLabel, FormControl, FormHelperText, Modal, Fade, CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';

const NotificationsForm = ({ onSendNotifications, users }) => {
  const { register, handleSubmit, control, watch, formState: { errors } } = useForm({
    defaultValues: {
      messageType: '',
      title: '',
      message: '',
      actionType: ''
    }
  });
  const messageType = watch('messageType');
  const actionType = watch('actionType');
  const [fileData, setFileData] = useState([]);
  const [text, setText] = useState('');
  const [progress, setProgress] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState('');
  const initialTemplates = [
    { label: 'Nombre', value: '#nombre ' },
    { label: 'Marca', value: '#marca ' },
    { label: 'Modelo', value: '#modelo ' },
    { label: 'Placa', value: '#placa ' }
  ];
  const [templates, setTemplates] = useState(initialTemplates);

  useEffect(() => {
    if (messageType !== 'excel') {
      setTemplates(initialTemplates);
    }
  }, [messageType]);

  useEffect(() => {
    setIsPopup(actionType === 'popup');
  }, [actionType]);

  const textRef = useRef(null);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const insertTemplate = (template) => {
    const cursorPosition = textRef.current.selectionStart;
    const newText = text.slice(0, cursorPosition) + template + text.slice(cursorPosition);
    setText(newText);
    textRef.current.focus();
    setTimeout(() => {
      textRef.current.selectionStart = textRef.current.selectionEnd = cursorPosition + template.length;
    }, 0);
  };

  const handleFileChange = (event) => {

    const reader = new FileReader();
    const file = event.target.files[0];
    console.log("file",file)
    if (!file) {
      console.error('No se seleccionó ningún archivo');
      return;
    }
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      if (data.length === 0) {
        console.error('El archivo está vacío');
        return;
      }
      // Añadir tokens según la cédula
      const mixedData = [];

      console.log("data", data)
      data.flatMap((item) => {
        const matchingUsers = users.filter((u) => u.Cedula == item.cedula);
        console.log("matchingUsers", item)
        mixedData.push(matchingUsers.map((user) => ({ ...item, devices: user.devices, cars: user.cars, ID: user.ID, Cedula :user.Cedula })));
      });
      const flattenedData = mixedData.flatMap(item => item);
      const firstObjectKeys = Object.keys(data[0]).filter(key => key !== '__rowNum__');
      console.log("flattenedData",flattenedData)
      setFileData(flattenedData);
      setTemplates(firstObjectKeys.map(key => ({
        label: key,
        value: `#${key.toLowerCase()} `
      })));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("file",file)
    setImageFile(file);
    setImageFileName(file ? file.name : '');
  };
  console.log("fileData",fileData)
  const renderModal = () => (
    <Modal
      open={isSending}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Fade in={isSending}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ outline: 'none', width: '200px', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}
        >
          <CircularProgress />
          <Box mt={2}>{`Progreso: ${progress}%`}</Box>
        </Box>
      </Fade>
    </Modal>
  );

  const onSubmit = async (data) => {
    setIsSending(true);
    let filesSending = users;
    if (data.messageType === 'excel') {
      filesSending = fileData;
    } else if (data.messageType === 'test') {
      filesSending = filesSending.filter((item) => item.tipoCuentas === '2');
    }
  console.log("JSON.stringify(filesSending)",JSON.stringify(filesSending))
    const formData = new FormData();
    formData.append('tipomensaje', data.actionType);
    formData.append('tipo_accion', data.messageType);
    formData.append('titulo', data.title);
    formData.append('mensaje', text);
    formData.append('accion', data.actionUrl);
    formData.append('files', JSON.stringify(filesSending));
  
    if (actionType === 'popup' && imageFile) {
      formData.append('url', imageFile);
    } else {
      formData.append('url', data.url);
    }
    console.log("requestOptions", JSON.stringify(formData));
    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow'
    };

    try {
      const response = await fetch('https://app.silaba.com/NOTIFICATIONS/mercadeo/v1/', requestOptions);
  
      const reader = response.body.getReader();
      const contentLengthHeader = response.headers.get('Content-Length');
      const contentLength = contentLengthHeader ? +contentLengthHeader : 0;
      let receivedLength = 0;
      let chunks = [];
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
  
        chunks.push(value);
        receivedLength += value.length;
  
        if (contentLength) {
          const percentCompleted = Math.round((receivedLength * 100) / contentLength);
          setProgress(percentCompleted);
        }
      }
  
      const chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }
  
      const result = new TextDecoder('utf-8').decode(chunksAll);
      const parsedResult = JSON.parse(result);
      if (parsedResult) {
        console.log('Notificaciones enviadas exitosamente');
      } else {
        console.log('Error al enviar notificaciones');
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('Error al enviar notificaciones');
    } finally {
      setIsSending(false);
      setProgress(0);
    }
  };
  

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <InputLabel id="active-users-label">Usuarios activos {users.length}</InputLabel>
      <FormControl fullWidth margin="normal" error={!!errors.messageType}>
        <InputLabel id="message-type-label">Tipo Mensaje</InputLabel>
        <Controller
          name="messageType"
          control={control}
          rules={{ required: 'Tipo Mensaje es requerido' }}
          render={({ field }) => (
            <Select
              labelId="message-type-label"
              id="message-type"
              label="Tipo Mensaje"
              {...field}
            >
              <MenuItem value="todos">Todos los Usuarios</MenuItem>
              <MenuItem value="excel">Usar XLSX (Excel)</MenuItem>
              <MenuItem value="test">Usuarios de Prueba</MenuItem>
            </Select>
          )}
        />
        {errors.messageType && <FormHelperText>{errors.messageType.message}</FormHelperText>}
      </FormControl>

      <TextField
        fullWidth
        label="Título de la notificación"
        margin="normal"
        {...register('title', { required: 'Título es requerido' })}
        error={!!errors.title}
        helperText={errors.title ? errors.title.message : ''}
      />

      {messageType === 'excel' ?
        <>
          <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', mb: 2, mt: 2 }}>
            <Button variant="contained" component="label">
              Subir XLS
              <input
                type="file"
                hidden
                accept=".xlsx, .xls"
                onChange={handleFileChange}
              />
            </Button>
          </Box>

          <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', mb: 2 }}>
            <InputLabel id="template-upload-label">Si desea otros campos para plantillas, puede cargar un XLSX</InputLabel>
          </Box>
        </> : null}

      {messageType !== 'excel' || (messageType === 'excel' && fileData.length > 0) ?
        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', mb: 2, mt: 2 }}>
          {templates.map((template) => (
            <Button
              key={template.value}
              variant="contained"
              onClick={() => insertTemplate(template.value)}
              sx={{ borderRadius: '5px', backgroundColor: '#e1e4ea', color: '#333', fontWeight: 'bold' }}
            >
              {template.label}
            </Button>
          ))}
        </Box>
        : null}

      <Controller
        name="message"
        control={control}
        rules={{ required: 'El escribir un mensaje' }}
        render={({ field }) => (
          <TextField
            inputRef={textRef}
            multiline
            rows={6}
            value={text}
            onChange={(e) => {
              handleTextChange(e);
              field.onChange(e);
            }}
            fullWidth
            variant="outlined"
            margin="normal"
            label="Mensaje"
            placeholder="Escribe tu mensaje aquí..."
            error={!!errors.message}
            helperText={errors.message ? errors.message.message : ''}
          />
        )}
      />

      <>
        <FormControl fullWidth margin="normal">
          <InputLabel id="action-type-label">Tipo Acción</InputLabel>
          <Controller
            name="actionType"
            control={control}
            render={({ field }) => (
              <Select
                labelId="action-type-label"
                id="action-type"
                label="Tipo Acción"
                {...field}
              >
                <MenuItem value="popup">Popup (Imagen)</MenuItem>
                <MenuItem value="url">URL (Enlace externo)</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        {isPopup ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <TextField
              fullWidth
              label="Imagen seleccionada"
              margin="normal"
              value={imageFileName}
              disabled
            />
            <Button variant="contained" component="label" size='large' sx={{
              height:'55px',
              minWidth:'155px',
              top:'3px'
            }}>
              Subir Imagen
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>
          </Box>
        ) : (
          <TextField
            fullWidth
            label="URL de la Acción"
            margin="normal"
            {...register('url')}
          />
        )}
      </>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size='large'
        >
          Enviar Notificaciones
        </Button>
        {isSending && renderModal()}
      </Box>
    </Box>
  );
};

export default NotificationsForm;
