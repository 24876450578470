import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Paper, Alert, Snackbar } from '@mui/material';
import NotificationsForm from './NotificationsForm';
import { fetchNotifications } from './notificationsSlice';

const NotificationsPage = () => {
    const dispatch = useDispatch();
    const notificationsState = useSelector((state) => state.notifications); // Cambiado para capturar todo el estado del slice primero
    const { users} = notificationsState; // Ahora desestructurando el estado del slice

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

    useEffect(() => {
        dispatch(fetchNotifications());
    }, []);

    const onSendNotifications = async (notifications) => {
        try {
            // Simula envío de notificaciones
            console.log('Enviando notificaciones:', notifications);
            setSnackbarMessage('Notificaciones enviadas con éxito');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Error al enviar notificaciones');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Panel de Control de Notificaciones
            </Typography>
            <NotificationsForm onSendNotifications={onSendNotifications} users={users}/>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default NotificationsPage;
