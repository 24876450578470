import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './features/auth/LoginPage';
import NotificationsPage from './features/notifications/NotificationsPage';


const ProtectedRoute = ({ children }) => {
    // Intenta obtener el usuario directamente desde localStorage
    const user = JSON.parse(localStorage.getItem('user'));
  
    if (!user) {
      // Usuario no autenticado, redirigir a la página de login
      return <Navigate to="/login" replace />;
    }
  
    return children;
  };

const AppRoutes = () => {
return (
    <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/" element={
    <ProtectedRoute>
        <NotificationsPage /> {/* Asumiendo que tienes un componente DashboardPage */}
    </ProtectedRoute>
    } />
    </Routes>
);
};

export default AppRoutes;