import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as authServiceLogin, logout as authServiceLogout, getCurrentUser as authServiceGetCurrentUser } from '../features/auth/AuthService';

const useAuth = () => {
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const login = async (userData) => {
    try {
      const response = await authServiceLogin(userData);
      navigate('/');  // Navegar a la página inicial después de un login exitoso
      setIsError(false);
      setMessage('');
      return response;
    } catch (error) {
      setIsError(true);
      setMessage(error.response ? error.response.data.message : 'Error de autenticación');
      throw error;
    }
  };

  const logout = () => {
    authServiceLogout();
    navigate('/login'); // Navegar a la página de login después del logout
  };

  return {
    login,
    logout,
    getCurrentUser: authServiceGetCurrentUser,
    isError,
    message,
  };
};

export default useAuth;
