import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Estado inicial
const initialState = {
    notifications: [],
    users: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: '',
};

// Thunk para fetch de datos de usuarios
export const fetchNotifications = createAsyncThunk(
    'notifications/fetchNotifications',
    async (_, thunkAPI) => {
        try {
            const response = await fetch('https://app.silaba.com/NOTIFICATIONS/users/v1/', {
                method: "POST",
                headers: {
                    // Tus headers van aquí
                },
                body: new FormData(), // o el body que necesites enviar
                redirect: "follow"
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message || 'Error al cargar los datos');
        }
    }
);

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.users = action.payload; // Asegúrate de actualizar el estado correctamente según tu estructura de datos
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload || 'Error al cargar los datos';
            });
    },
});

export const { reset } = notificationsSlice.actions;
export default notificationsSlice.reducer;
