import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    return (
        <AppBar position="static">
        <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
            Notifications-GS
            </Typography>
            <Button color="inherit" onClick={handleLogout}>
            Logout
            </Button>
        </Toolbar>
        </AppBar>
    );
};

export default NavBar;
